import React, { Component } from 'react';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import { AgGridReact } from 'ag-grid-react';

import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import "flag-icons/css/flag-icons.min.css";
import './leads.css';
import * as consts from '../consts';

import SplitPane from 'split-pane-react';
import 'split-pane-react/esm/themes/default.css';

const MSG_UNLOCK_LEADS = 'Please use credits to unmask the lead';

const defaultColDef = {
    filter: true,
    sortable: true,
    resizable: true,
    enableRowGroup: true
};
const autoGroupColumnDef = {
  pinned: 'left', // Pin the group column to the left
}
// const defaultSideBar= '' // 'filters' or 'columns'
const defaultSideBar = {
  toolPanels: [
    {
      id: 'filters',
      labelDefault: 'Filters',
      labelKey: 'filters',
      iconKey: 'filter',
      toolPanel: 'agFiltersToolPanel',
      toolPanelParams: {
        suppressExpandAll: true,
        suppressFilterSearch: true,
      }
    },
    {
      id: 'columns',
      labelDefault: 'Columns',
      labelKey: 'columns',
      iconKey: 'column',
      toolPanel: 'agColumnsToolPanel',
      toolPanelParams: {
        suppressExpandAll: true,
        suppressFilterSearch: true,
      },
    },
  ],
  // defaultToolPanel: 'filters',
};
const rowSelection = 'single';
const rowGroupPanelShow = 'onlyWhenGrouping';

const rowModelType = 'serverSide';
const cacheBlockSize = 100;

class Leads extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isNewJob: false,
            isGridView: false,
            gridApi: null,
            rowData: [],
            columnDefs: [],
            selectedData: [],
            selectedUtld: '',
            updatingRowData: false,
            gsplit: [`0%`, 'auto'],
            cards: [],
            selectedCard: 0,
            lastVisibleCard: 0,
            filterMode: 0,
            listCategories: [],
            filterKeys: [],
        }

        this.onGridReady = this.onGridReady.bind(this);
        this.onGridRefresh = this.onGridRefresh.bind(this);
        this.getRows = this.getRows.bind(this);
        this.getRowId = this.getRowId.bind(this);
        this.isServerSideGroupOpenByDefault = this.isServerSideGroupOpenByDefault.bind(this);
        this.getChildCount = this.getChildCount.bind(this);
        this.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.fetchData = this.fetchData.bind(this);
        this.onModelUpdated = this.onModelUpdated.bind(this);
        this.onRowDataUpdated = this.onRowDataUpdated.bind(this);
        this.onFirstDataRendered = this.onFirstDataRendered.bind(this);
        this.onFilterChanged = this.onFilterChanged.bind(this);
        this.groupCountComparator = this.groupCountComparator.bind(this);
        this.getWhereSelectedCategories = this.getWhereSelectedCategories.bind(this);
        this.fetchCategoryData = this.fetchCategoryData.bind(this);
        this.onStoreRefreshed = this.onStoreRefreshed.bind(this);
        this.checkRenderer = this.checkRenderer.bind(this);
        this.statusRenderer = this.statusRenderer.bind(this);
        this.flagRenderer = this.flagRenderer.bind(this);
        this.tldRenderer = this.tldRenderer.bind(this);
        this.urlRenderer = this.urlRenderer.bind(this);
        this.toggleView = this.toggleView.bind(this);
        this.refreshCards = this.refreshCards.bind(this);
        this.cardSelectionChanged = this.cardSelectionChanged.bind(this);
        this.applyFilter = this.applyFilter.bind(this);
        this.removeFilter = this.removeFilter.bind(this);
        this.removeKeywordsFilter = this.removeKeywordsFilter.bind(this);
        this.removeCategoriesFilter = this.removeCategoriesFilter.bind(this);
        this.handleCardsScroll = this.handleCardsScroll.bind(this);
        this.unlockClicked=this.unlockClicked.bind(this);
        this.unlockLeads=this.unlockLeads.bind(this)
        this.unlockAllClicked=this.unlockAllClicked.bind(this);
        this.unlockAllLeads=this.unlockAllLeads.bind(this);
        this.exportLeads = this.exportLeads.bind(this);
        this.createEmailCampaign = this.createEmailCampaign.bind(this);
        this.agRequestRef = React.createRef();
        this.emailCampaignIdRef=React.createRef(-1);
    };
 
    handleCardsScroll(e) {
      const cardsList = e.currentTarget;
      const top = (cardsList.scrollTop === 0);
      const bottom = (Math.abs(cardsList.scrollHeight - (cardsList.scrollTop + cardsList.clientHeight)) <= 500);
      if (top) {
        // this.state.gridApi?.ensureIndexVisible(0, 'top')
      } else if (bottom) {
        const index = this.state.cards.length;
        this.state.gridApi?.ensureIndexVisible((index - 1), 'bottom');
        const lastVisibleIndex = Math.max(0, (index - 1));
        const lastVisibleCard = this.state.cards[lastVisibleIndex].id;
        this.setState({ lastVisibleCard: lastVisibleCard });
      } else {
        this.setState({ lastVisibleCard: 0 });
      }
    }

    checkRenderer (params) {
      return (<div className='d-flex justify-content-center'><i className={`${(params.value && params.value === 1) ? 'bi bi-file-check' : ''}`}></i></div>)
    };

    flagRenderer (params) {
      return ((params.value && params.value !== '') ? <div title={params.data ? params.data.countryname : ''} className={`fi fi-${params.value.toLowerCase()}`}></div> : '')
    }

    tldRenderer (params) {
      return ((params.value && params.value !== '') ? (
        (params.data ? params.data.isunlocked : params.isunlocked) ? (
          <a rel="noreferrer" target='_blank' href={`https://${params.value}`}>{params.value}</a>
      ) : (
        <p onClick={() => alert(MSG_UNLOCK_LEADS)}>{params.value}</p>
      )
      ) : '' )
    }

    urlRenderer (params) {
      return ((params.value && params.value !== '') ? (
        (params.data ? params.data.isunlocked : params.isunlocked) ? (
          <div className='d-flex justify-content-center mx-1'>
            <a rel="noreferrer" target='_blank' href={`${params.value}`}><i title={params.title} className={`${params.icon}`}></i></a>
          </div> 
        ) : (
          <div className='d-flex justify-content-center mx-1'>
            <i title={params.title} className={`${params.icon}`} onClick={() => alert(MSG_UNLOCK_LEADS)}></i>
          </div> 
        ) 
        ) : '' )
    }

    statusRenderer (params) {
      return (<div className='mx-auto' style={{width: '18px', height:'18px'}}><i title={`Site Scan ${(params.value === 1) ? 'Completed' : 'Pending'}`} className={`bi ${(params.value === 1) ? 'bi-check-circle-fill' : 'bi-circle-half'} text-dark`}></i></div>)
    }

    fetchData = async(jobId, isNewJob, jobChanged) => {
      if (jobId <= 0) return;

      if (!this.state.updatingRowData) {
        this.setState({ updatingRowData: true });
        this.props.uld(true);
        if (isNewJob || jobChanged) {
          this.setState({ isNewJob: true, rowData: [], selectedData: [], selectedUtld: '', selectedCard: 0 })
          this.props.data([]);
          this.props.bardata({series: [], labels: []});
          this.props.piedata({series: [], labels: []});
          this.props.catdata([]);
          this.removeKeywordsFilter();
          this.removeCategoriesFilter();
          this.applyFilter({index: 0, value: 0});
          this.props.lrcu(0);
          this.fetchCategoryData(jobId);
          this.refreshCards();
        }

        if (jobId > 0) {
          // this.state.gridApi?.setServerSideDatasource({getRows: this.getRows});
          this.state.gridApi?.refreshServerSide({purge: jobChanged});

          var rowCount = this.state.gridApi?.getDisplayedRowCount();

          if (rowCount > 0) {
            /*
            await axios.get(`/getleadcount/${jobId}`)
            .then(result => {
              var series = [];
              var labels = [];
              
              Object.keys(result.data[0]).forEach(key => labels.push(key));
              Object.values(result.data[0]).forEach(value => series.push(value));

              this.props.bardata({series: series, labels: labels});
            })
            .catch((error) => {
                console.log(error);
            })

            await axios.get(`/getleadsocialcount/${jobId}`)
            .then(result => {
              var series = [];
              var labels = [];
              
              Object.keys(result.data[0]).forEach(key => labels.push(key));
              Object.values(result.data[0]).forEach(value => series.push(value));

              this.props.sbardata({series: series, labels: labels});
            })
            .catch((error) => {
                console.log(error);
            })
            */
            await axios.get(`/getcategorycount/${jobId}`)
            .then(result => {
              var series = [];
              var labels = [];
              
              result.data.forEach(row => {
              series.push(row.ucatcount);
              labels.push(row.ucategory);
              });

              this.props.piedata({series: series, labels: labels});
            })
            .catch((error) => {
                console.log(error);
            }); 

            const rowNum = this.state.gridApi && this.state.gridApi.getFirstDisplayedRow();
            rowNum && this.state.gridApi && this.state.gridApi.getDisplayedRowAtIndex(rowNum).setSelected(true);
          };
        }
        // this.setState({ updatingRowData: false });
      }
    }

    // Custom comparator function to sort groups by group counts
    groupCountComparator(valueA, valueB, nodeA, nodeB, isInverted) {
      // Get the group counts for the two groups
      const countA = nodeA ? nodeA.allChildrenCount : valueA; // Number of children in group A
      const countB = nodeB ? nodeB.allChildrenCount : valueB; // Number of children in group B

      // Compare the counts and return the result
      if (countA < countB) {
        return -1;
      } else if (countA > countB) {
        return 1;
      } else {
        return 0;
      }
    }
    
    onGridReady(params) {
        this.setState({
            gridApi: params.api,
        })
        params.api.setServerSideDatasource({getRows: this.getRows});
      }

    onGridRefresh(params) {
      // this.state.gridApi.setServerSideDatasource({getRows: this.getRows});
    }

    isServerSideGroupOpenByDefault(params) {
      var rowNode = params.rowNode;

      return (rowNode.group && rowNode.uiLevel === 0)
    }

    getRowId(params) {
      return params.data.id;
    }

    getWhereSelectedCategories() {
      const selectedCategoryData = (this.state.listCategories.length === this.props.selcatdata.length) ? [] : this.props.selcatdata;
      const selectedCategoryCSV = selectedCategoryData.join(',');
      return selectedCategoryCSV
    }

    getRows(params) {
      const selPlanId = this.props.planId;
      const selKeywords = this.props.selkwdata;
      const selCategories = this.getWhereSelectedCategories();
      const serverURL = `/api/getagleads/${this.props.jid}}`;
      this.agRequestRef.current = params.request;
      
      this.setState({ updatingRowData: true });
      this.props.uld(true);
      fetch(serverURL, {
        method: 'post',
        body: JSON.stringify({...params.request, selPlanId, selKeywords, selCategories}),
        headers: { "Content-Type": "application/json; charset=utf-8" }
      })
      .then(httpResponse => httpResponse.json())
      .then(response => {
        params.successCallback(response.rows, response.lastRow);

        this.props.lrcu(response.totals.Totals);

        var series = [];
        var labels = [];
        Object.keys(response.totals).forEach(key => labels.push(key));
        Object.values(response.totals).forEach(value => series.push(value));
        this.props.bardata({series: series.slice(0,15), labels: labels.slice(0,15)});
        this.props.sbardata({series: series.slice(16,22), labels: labels.slice(16,22)});

        this.setState({ updatingRowData: false });
        this.props.uld(false);
      })
      .catch(error => {
        this.props.uld(false);
        console.error(error);
        params.failCallback();
      })
    }

    getChildCount(data) {
      return data ? data.childCount : undefined
    }
    
    onSelectionChanged() {
      if (this.state.isGridView) {
        const data = this.state.gridApi.getSelectedRows();
        this.setState({
            selectedData: data,
            selectedUtld: data.utld
        })
        this.props.data(data);
      }
    }

    onModelUpdated = (params) => {
      this.setState({ updatingRowData: false });
      this.props.uld(false);
      this.refreshCards();
    }

    onStoreRefreshed = (params) => {
      this.setState({ updatingRowData: false });
      this.props.uld(false);
    }

    onRowDataUpdated = (params) => {
      // onRowDataUpdated works only with client side data model
      // this.refreshCards();
    }

    onFirstDataRendered = (params) => {
      var cols = [
        { field: 'ucategory', headerName: 'Category', filter: false, pinned: 'left', rowGroup: false, hide: false, comparator: this.groupCountComparator},
        { field: 'name', headerName: 'Name', filter: 'agTextColumnFilter', pinned: 'left' },
        { field: 'tld', headerName: 'Website', filter: 'agTextColumnFilter', pinned: 'left', cellRenderer: this.tldRenderer },
        { field: 'address', headerName: 'Address', filter: 'agTextColumnFilter', hide: true },
        { field: 'city', headerName: 'City', filter: 'agTextColumnFilter', hide: true },
        { field: 'state', headerName: 'State', filter: 'agTextColumnFilter', hide: true },
        { field: 'countrycode', headerName: 'Country', filter: 'agTextColumnFilter', hide: false, width: 100, cellStyle: {textAlign: 'center'}, cellRenderer: this.flagRenderer },
        { field: 'countryname', headerName: 'Country Name', filter: 'agTextColumnFilter', hide: true },
        // { field: 'contactnumber', headerName: 'Contact', filter: 'agTextColumnFilter', hide: true },
        { field: 'phones', headerName: 'Phones', filter: 'agTextColumnFilter', hide: true },
        { field: 'emails', headerName: 'Emails', filter: 'agTextColumnFilter', hide: true },
        { field: 'people', headerName: 'People', filter: 'agTextColumnFilter', hide: true },
        { field: 'iscrawlcomplete', headerName: 'Status', filter: 'agTextColumnFilter', width: 75, cellStyle: {textAlign: 'center'}, cellRenderer: this.statusRenderer },
        { field: 'isformonsite', headerName: 'Form', filter: 'agNumberColumnFilter', width: 75, cellStyle: {textAlign: 'center'}, cellRenderer: this.checkRenderer },
        { field: 'contacturl', headerName: 'Contact', filter: 'agTextColumnFilter', width: 100, cellRenderer: this.urlRenderer, cellRendererParams: { icon: 'bi bi-card-heading', title: 'Contact Page' } },
        { field: 'abouturl', headerName: 'About', filter: 'agTextColumnFilter', width: 100, cellRenderer: this.urlRenderer, cellRendererParams: { icon: 'bi bi-buildings', title: 'About Page' } },
        { field: 'leadershipurl', headerName: 'Team', filter: 'agTextColumnFilter', width: 100, cellRenderer: this.urlRenderer, cellRendererParams: { icon: 'bi bi-people-fill', title: 'Team Page' } },
        { field: 'careersurl', headerName: 'Careers', filter: 'agTextColumnFilter', width: 100, cellRenderer: this.urlRenderer, cellRendererParams: { icon: 'bi bi-person-workspace', title: 'Careers Page' } },
        { field: 'linkedinurl', headerName: 'LinkedIn', filter: 'agTextColumnFilter', width: 100, cellRenderer: this.urlRenderer, cellRendererParams: { icon: 'bi bi-linkedin', title: 'LinkedIn' } },
        { field: 'facebookurl', headerName: 'Facebook', filter: 'agTextColumnFilter', width: 100, cellRenderer: this.urlRenderer, cellRendererParams: { icon: 'bi bi-facebook', title: 'Facebook' } },
        { field: 'twitterurl', headerName: 'Twitter', filter: 'agTextColumnFilter', width: 100, cellRenderer: this.urlRenderer, cellRendererParams: { icon: 'bi bi-twitter-x', title: 'Twitter' } },
        { field: 'instagramurl', headerName: 'Instagram', filter: 'agTextColumnFilter', width: 100, cellRenderer: this.urlRenderer, cellRendererParams: { icon: 'bi bi-instagram', title: 'Instagram' } },
        { field: 'youtubeurl', headerName: 'Youtube', filter: 'agTextColumnFilter', width: 100, cellRenderer: this.urlRenderer, cellRendererParams: { icon: 'bi bi-youtube', title: 'YouTube' } },
        { field: 'pinteresturl', headerName: 'Pinterest', filter: 'agTextColumnFilter', width: 100, cellRenderer: this.urlRenderer, cellRendererParams: { icon: 'bi bi-pinterest', title: 'Pinterest' } },
        { field: 'whatsappurl', headerName: 'Whatsapp', filter: 'agTextColumnFilter', width: 100, cellRenderer: this.urlRenderer, cellRendererParams: { icon: 'bi bi-whatsapp', title: 'WhatsApp' } },
        { field: 'revenue', headerName: 'Estimated Revenue', filter: 'agTextColumnFilter', hide: true },
        { field: 'headcount', headerName: 'Estimated Head Count', filter: 'agTextColumnFilter', hide: true },
        { field: 'funding', headerName: 'Funding', filter: 'agTextColumnFilter', hide: true },
        { field: 'acquisitions', headerName: 'Acquisitions', filter: 'agTextColumnFilter', hide: true },
        { field: 'investments', headerName: 'Investments', filter: 'agTextColumnFilter', hide: true },
        { field: 'founded', headerName: 'Founded', filter: 'agTextColumnFilter', hide: true },
        { field: 'competitors', headerName: 'Competitors', filter: 'agTextColumnFilter', hide: true },
        { field: 'ceo_name', headerName: 'CEO', filter: 'agTextColumnFilter', hide: true },
        { field: 'comp_type', headerName: 'Type', filter: 'agTextColumnFilter', hide: true },
        { field: 'sectors', headerName: 'Sectors', filter: 'agTextColumnFilter', hide: true },
        { field: 'sic_codes', headerName: 'SIC Codes', filter: 'agTextColumnFilter', hide: true },
        { field: 'ispubliccompany', headerName: 'Investor Page', filter: 'agNumberColumnFilter', hide: true, width: 75, cellStyle: {textAlign: 'center'}, cellRenderer: this.checkRenderer },
        { field: 'isproductcompany', headerName: 'Product Page', filter: 'agNumberColumnFilter', hide: true, width: 75, cellStyle: {textAlign: 'center'}, cellRenderer: this.checkRenderer },
        { field: 'isshoppingcart', headerName: 'Shopping Cart', filter: 'agNumberColumnFilter', hide: true, width: 75, cellStyle: {textAlign: 'center'}, cellRenderer: this.checkRenderer },
        { field: 'ismobileapp', headerName: 'Mobile App', filter: 'agNumberColumnFilter', hide: true, width: 75, cellStyle: {textAlign: 'center'}, cellRenderer: this.checkRenderer },
        { field: 'isunlocked', headerName: 'Unlocked', filter: 'agNumberColumnFilter', hide: false, width: 75, cellStyle: {textAlign: 'center'}, cellRenderer: this.checkRenderer },
      ];
      // Object.keys(result.data[0]).forEach(key => {cols.push({field : key}); // to add all column definitions
      this.setState({columnDefs: cols})
    }

    toggleView(currentView) {
      this.setState({
        isGridView: currentView,
        gsplit: currentView ? ["100%", 'auto'] : ["0%", 'auto']
      })
      this.refreshCards();
    }

    refreshCards() {
      const isNewJob = this.state.isNewJob;
      var cards = [];
      // forEachNodeAfterFilter works only with client side data model
      // this.state.gridApi && this.state.gridApi.forEachNodeAfterFilter((node, index) => {if (!node.group) cards.push(node.data)});
      this.state.gridApi && this.state.gridApi.forEachNode((node, index) => {if ((!node.group) && (node.data)) cards.push(node.data)});
      if (isNewJob || (cards.length > 0) || (!this.props.isCrawling)) {
        this.setState({cards: cards});
        this.props.data([]);
        if (isNewJob) { this.setState({ isNewJob: false }) };
      }
      
      if (cards.length > 0) { 
        const selCard = Math.max(cards.findIndex(row => (row.utld === this.state.selectedUtld)), 0);
        const selData = cards[selCard];
        this.setState({
          selectedCard: selCard,
          selectedData: selData,
          selectedUtld: selData.utld
        });
        this.props.data([selData]);
      }
    }
    
    cardSelectionChanged(rowid) {
      
      const data = this.state.cards[rowid];
      // const data = [this.state.rowData[rowid]];
      this.setState({
        selectedData: data,
        selectedUtld: data.utld,
        selectedCard: rowid,
        lastVisibleCard: rowid,
      })
      this.props.data([data]);
    }

    onFilterChanged() {
      const currentFilterModel = this.state.gridApi && this.state.gridApi.getFilterModel();
      const filterKeys = Object.keys(currentFilterModel);
      this.setState({filterKeys: filterKeys});
    }

    async fetchCategoryData(jobId) {
      const agRequest = this.agRequestRef.current;
      const viewParams = `[${jobId},1,"",""]`;
      const tableName = ` vw_leads `;
      const columnName = 'ucategory';

      try {
        const request1 = axios.post(`/getsetcolumnfiltervalues`, {...agRequest, viewParams, tableName, columnName});
        const request2 = axios.get(`/getjobrelatedcategories/${jobId}`);
        const [response1, response2] = await Promise.all([request1, request2]);

        const selectedCategories = response2.data && JSON.parse(response2.data.categories);
        const relatedCategories = response2.data && JSON.parse(response2.data.relatedcategories);

        let result = [];
        response1.data && response1.data.map((row) => {
          let ucategory = row.ucategory;
          let count = row.count;
          let ucattype = 0;
          if (selectedCategories.includes(row.ucategory)) {
            ucattype = 2
          } else if (relatedCategories.includes(row.ucategory)) {
            ucattype = 1
          }
          result.push({ucategory, count, ucattype});
          return true
        })

        this.props.catdata(result);
        this.setState({listCategories: result})
        this.props.ucd(false);
      } catch (err) {
        this.props.ucd(false);
        console.log(err);        
      }

      // const request = {url:`/getsetcolumnfiltervalues`, method:'POST', data:{...agRequest, viewParams, tableName, columnName}};
      // this.props.ucd(true);
      // await axios(request)
      // .then(result => {
      //   this.props.catdata(result.data)
      //   this.setState({listCategories: result.data})
      //   this.props.ucd(false);
      // })
      // .catch((error) => {
      //   this.props.ucd(false);
      //   console.log(error);
      // })
    }

    applyFilter(filter) {
      let filterModel = this.state.gridApi && this.state.gridApi.getFilterModel();
      const mode = parseInt(filter.index);
      const value = filter.value;

      if (mode === 0) {
        filterModel = {}
      } else if (mode === 1) {
        filterModel['isunlocked'] = {type: 'equals', filter: 0};
      } else if (mode === 2) {
        filterModel['isunlocked'] = {type: 'equals', filter: 1}
      } else if (mode === 3) {
        filterModel['tld'] = {type: 'notBlank', filter: ''}
      } else if (mode === 4) {
        filterModel['address'] = {type: 'notBlank', filter: ''}
      } else if (mode === 5) {
        filterModel['phones'] = {type: 'notBlank', filter: ''}
      } else if (mode === 6) {
        filterModel['emails'] = {type: 'notBlank', filter: ''}
      } else if (mode === 7) {
        filterModel['isformonsite'] = {type: 'greaterThan', filter: 0}
      } else if (mode === 8) {
        filterModel['contacturl'] = {type: 'notBlank', filter: ''}
      } else if (mode === 9) {
        filterModel['abouturl'] = {type: 'notBlank', filter: ''}
      } else if (mode === 10) {
        filterModel['leadershipurl'] = {type: 'notBlank', filter: ''}
      } else if (mode === 11) {
        filterModel['careersurl'] = {type: 'notBlank', filter: ''}
      } else if (mode === 12) {
        filterModel['ispubliccompany'] = {type: 'equals', filter: 1}
      } else if (mode === 13) {
        filterModel['isproductcompany'] = {type: 'equals', filter: 1}
      } else if (mode === 14) {
        filterModel['isshoppingcart'] = {type: 'equals', filter: 1}
      } else if (mode === 15) {
        filterModel['ismobileapp'] = {type: 'equals', filter: 1}
      } else if (mode === 16) {
        filterModel['iscrawlcomplete'] = value ? {} : {type: 'equals', filter: 1}
      } else if (mode === 80) {
        filterModel['linkedinurl'] = {type: 'notBlank', filter: ''}
      } else if (mode === 81) {
        filterModel['facebookurl'] = {type: 'notBlank', filter: ''}
      } else if (mode === 82) {
        filterModel['twitterurl'] = {type: 'notBlank', filter: ''}
      } else if (mode === 83) {
        filterModel['instagramurl'] = {type: 'notBlank', filter: ''}
      } else if (mode === 84) {
        filterModel['youtubeurl'] = {type: 'notBlank', filter: ''}
      } else if (mode === 85) {
        filterModel['pinteresturl'] = {type: 'notBlank', filter: ''}
      } else if (mode === 86) {
        filterModel['whatsappurl'] = {type: 'notBlank', filter: ''}
      }

      this.state.gridApi && this.state.gridApi.setFilterModel(filterModel);
      if (!this.state.isGridView) this.refreshCards();
    }

    removeKeywordsFilter(e) {
      this.props.ckwd()
    }

    removeCategoriesFilter(e) {
      this.props.cscd()
    }

    removeFilter(e) {
      const filterKey = e.currentTarget.dataset.value;
      let filterModel = this.state.gridApi && this.state.gridApi.getFilterModel();
      filterModel[filterKey] = {};
      this.state.gridApi && this.state.gridApi.setFilterModel(filterModel);
      if (!this.state.isGridView) this.refreshCards();
      
    }

    unlockClicked=async (id,jobid)=>{
      const request={url:`/usercredits/${this.props.loggedInUserId}`,method:'GET'}
      await axios(request)
      .then((result)=>{
        if(result.data.noofcredits>0)
        {
          const res=window.confirm("Are you sure you want to unlock this lead ?")
          if(res)
            this.unlockLeads([id],jobid,this.props.loggedInUserId)
        }
        else alert("There are not enough credits")
      })
      .catch((err)=>{
        console.log(err)
        alert('Something went wrong, Please try again after some time')
      })
    }

    unlockLeads= async(leadIds,jobId,userId)=>{
      const request={
        url:'/unlockleads',
        method:'POST',
        data:{leadIds,jobId,userId},
      }
      await axios(request)
      .then((result)=>{
        result.data.forEach(lead=>{

          //update in cards
          const updatedCards = this.state.cards.map(card=>{
            if(card.id===lead.id)
              card=lead
            return card
          })
          this.setState({cards: updatedCards});

          const elem_leadLock=document.getElementById(`lead-lock-${lead.id}`)
          elem_leadLock.className += ' d-none';

          const elem_leadPhone=document.getElementById(`lead-phone-${lead.id}`)
          elem_leadPhone.innerHTML=`<p class='m-0'>${(!lead.phones) ? '' :lead.phones?.split(';')[0]}</p>`

          const elem_leadEmail=document.getElementById(`lead-email-${lead.id}`)
          elem_leadEmail.innerHTML= `<p class='m-0'>${(!lead.emails) ? '' :lead.emails?.split(';')[0]}</p>`

        })
        
        if(leadIds.length>0)
        {
          let rowid=this.state.cards.findIndex(row => (row.id === leadIds[0]))
          rowid=rowid>0?rowid:0
          this.cardSelectionChanged(rowid)
        }
      
        
      })
      .catch((err)=>{
        alert('Something went wrong, please try again after sometime')
      })
    }

    unlockAllClicked=async (jobId)=>{
      const selPlanId = this.props.planId;
      const selKeywords = this.props.selkwdata;
      const selCategories = this.getWhereSelectedCategories();
      const agRequest = this.agRequestRef.current;
      try{

          const request={url:`/usercredits/${this.props.loggedInUserId}`,method:'GET'}
          const usercredits=(await axios(request)).data.noofcredits
          
          const request2 = {
            url:`/getlockedleadcountbylist/${jobId}`,
            method:'POST',
            data:{agRequest, selPlanId, selKeywords, selCategories},
            headers: { "Content-Type": "application/json; charset=utf-8" }
          };

          const lockedLeadCount=(await axios(request2)).data.leadcount;

          if(lockedLeadCount===0)
          {
            if(Object.keys(agRequest.filterModel).length>0)
            {
              alert('All filtered leads are unlocked')
            }
            else alert('This list is fully unlocked')
          }
          if(lockedLeadCount>0)
          {
            if(usercredits>=lockedLeadCount)
            {
              const res=window.confirm(`Are you sure you want to unlock ${lockedLeadCount} leads in selected list?`)
              if(res)
              {
                this.unlockAllLeads(jobId,this.props.loggedInUserId,agRequest)
                .then(()=>{
                  // alert(`${lockedLeadCount} leads unlocked from selected list`)
                  this.fetchData(jobId, false, false)
                  this.refreshCards();
                })
                .catch((err)=>{
                  console.log(err)
                })
              }
            }
            else alert(`Selected list has ${lockedLeadCount} locked leads. And you have ${usercredits} credits. Required ${lockedLeadCount-usercredits} more credits to unlock this list`)
          }
      }
      catch(exc)
      {
        console.log(exc)
      }
    }

    unlockAllLeads= async(jobId,userId,agRequest)=>{
      const selPlanId = this.props.planId;
      const selKeywords = this.props.selkwdata;
      const selCategories = this.getWhereSelectedCategories();

      const request={
        url:'/unlockallleads',
        method:'POST',
        data:{jobId,userId,agRequest,selPlanId,selKeywords,selCategories},
        headers: { "Content-Type": "application/json; charset=utf-8" }
      }
      return axios(request)
    }

    exportLeads = async(jobId, fileName) => {
      const selPlanId = this.props.planId;
      const selKeywords = this.props.selkwdata;
      const selCategories = this.getWhereSelectedCategories();
      const agRequest = this.agRequestRef.current;
      const request = {jobId, fileName, selPlanId, selKeywords, selCategories, agRequest};
      const serverApiUrl = '/api/exportleads';

      await fetch(serverApiUrl, {
        method: 'post',
        body: JSON.stringify(request),
        headers: { "Content-Type": "application/json; charset=utf-8" }
      })
      .then(response => {
        response.blob().then(blob => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement('a');
          a.href = url;
          a.download = `${fileName}.xlsx`;
          a.click();
          a.remove();
          this.props.lexu(false)
        })
      })
      .catch(error => {
        console.error(error)
        this.props.lexu(false)
      })
    }

    createEmailCampaign= async(selectedEmailTemplateId,selectedJobId,createdby)=>{
      const selPlanId = this.props.planId;
      const selKeywords = this.props.selkwdata;
      const selCategories = this.getWhereSelectedCategories();
      const agRequest = this.agRequestRef.current;
      // const request = {jobId, fileName, selKeywords, selCategories, agRequest};
      // const serverApiUrl = '/api/exportleads';

      const request = {
          url: '/emailcampaigns',
          data: {templateid:selectedEmailTemplateId, smtpcredentialsid:0, jobid:selectedJobId,createdby:createdby, selPlanId, selKeywords, selCategories, agRequest, fromLeadsScreen:1},
          method: "POST"
      }

      if (request) {
          axios(request)
          .then((result) => {
            this.emailCampaignIdRef.current=result.data.campaignid
              console.log('emailcampaignid ',this.emailCampaignIdRef.current)
              window.open(`/campaigns/mail-templates/${this.emailCampaignIdRef.current}`,'_self')
          })
          .catch((err) => {
              console.log('error occured while creating email campaign', err)
          })
      }
    }

    createFormCampaign = async(tid, jid, mid, excludeCaptcha, excludeFailed, startedby) => {
      const selPlanId = this.props.planId;
      const selKeywords = this.props.selkwdata;
      const selCategories = this.getWhereSelectedCategories();
      const agRequest = this.agRequestRef.current;

      await axios.post(
        `/startformcampaign`,
        {
          tid, 
          jid,
          mid,
          excludeCaptcha,
          excludeFailed,
          startedby,
          selPlanId,
          selKeywords,
          selCategories,
          agRequest,
        });
    }

    render () {
      
    return (
      <React.Fragment>
        <div>
          {(this.props.selkwdata && this.props.selkwdata.length > 0) ? (
          <Button id={`filterkey-kw`} size="sm" variant="outline-primary" className='ms-1 rounded' onClick={this.removeKeywordsFilter}>
            <i class="bi bi-x-circle-fill me-2"></i>Hidden Gems
          </Button>
          ) : ''}
          {(this.props.selcatdata && this.props.selcatdata.length > 0) ? (
          <Button id={`filterkey-cat`} size="sm" variant="outline-primary" className='ms-1 rounded' onClick={this.removeCategoriesFilter}>
          <i class="bi bi-x-circle-fill me-2"></i>Categories
          </Button>
          ) : ''}
          {this.state.filterKeys.map((key, index) => (
          <Button id={`filterkey-${index}`} data-value={key} size="sm" variant="outline-primary" className='ms-1 rounded' onClick={this.removeFilter}>
            <i class="bi bi-x-circle-fill me-2"></i>{this.state.columnDefs.find(o => o.field === key).headerName}
          </Button>
          ))}
        </div>
        <SplitPane split="horizontal" allowResize={false} sizes={this.state.gsplit} resizerSize={5} onChange={(data) => this.setState({gsplit: data})}>
          <div className="ag-theme-balham" style={{height: '100%'}}>
            <AgGridReact 
              columnDefs={this.state.columnDefs}
              defaultColDef={defaultColDef}
              autoGroupColumnDef={autoGroupColumnDef}
              sideBar={defaultSideBar}
              animateRows={true}
              rowMultiSelectWithClick={false}
              rowSelection={rowSelection}
              rowGroupPanelShow={rowGroupPanelShow}
              onGridReady={this.onGridReady}
              onGridRefresh={this.onGridRefresh}
              onSelectionChanged={this.onSelectionChanged}
              onRowDataUpdated={this.onRowDataUpdated}
              onModelUpdated={this.onModelUpdated}
              onFirstDataRendered={this.onFirstDataRendered}
              onFilterChanged={this.onFilterChanged}
              onStoreRefreshed={this.onStoreRefreshed}
              rowModelType={rowModelType}
              getChildCount={this.getChildCount}
              cacheBlockSize={cacheBlockSize}
              alwaysShowHorizontalScroll={false}
              alwaysShowVerticalScroll={false}
            ></AgGridReact>
          </div>
        {this.state.cards ? (
        <div className={`h-100 overflow-auto d-flex flex-wrap`} onScroll={this.handleCardsScroll}>
          <div className={`w-100 justify-content-center ${((this.state.updatingRowData && (!this.props.isCrawling)) || (this.state.cards.length > 0)) ? 'd-none' : 'd-flex'}`}>
            <p style={{fontFamily: "var(--font-family-sans-serif)"}}>
                There's nothing here, yet.
            </p>
          </div>
          {this.state.cards.map((row, index) => (
          <Col xs={2} className='m-1 mb-4' style={{maxWidth: 'calc(16.67% - 9px)'}}> {/* , display: `${(this.state.updatingRowData && (!this.props.isCrawling)) ? 'none' : 'block'}` */}
            <Card 
              id={`lead-${row.id}`}
              key={index} 
              onClick={() => this.cardSelectionChanged(index,undefined)}
              className={`m-1 p-0 text-primary border border-2 ${(index === this.state.selectedCard) ? 'border-primary shadow-lg' : 'shadow'}`} 
            >
              <Card.Header 
                className='m-0 p-0 text-white d-flex align-content-end flex-column'
                style={{height: '150px', backgroundImage: `url(${consts.getBgUrl(row.ogimage, row.comp_logo, row.gimgurl)})`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundColor: '#b8cdfb', backgroundSize: `${consts.getBgSize(row.ogimage, row.comp_logo, row.gimgurl)}`}}
              >
                <div className="m-0 p-1 d-flex align-content-end">
                  <div id={`fi-${index}`} title={row.countryname} className={`me-auto mb-auto fi fi-${row.countrycode?.toLowerCase()}`}></div>
                  {(row.isunlocked===0)?
                    <Button id={`lead-lock-${row.id}`} title="Unlock" size="sm" variant="primary" style={{ borderRadius: "50%"}} className="ms-auto mr-n2 border border-gray" onClick={() => this.unlockClicked(row.domainid,row.jobid)}>
                      <i class="bi bi-lock" ></i>
                    </Button>:''
                  }
                </div>
                <span className='mt-auto mx-1 mb-1 px-1 rounded dark-glass'>{row.ucategory}</span>
              </Card.Header>
              <Card.Body className='p-2 d-flex align-content-end flex-column'>
                <Card.Text className='h6'>
                  {row.tld ? (
                    row.isunlocked ? (
                    <a rel="noreferrer" target='_blank' href={`https://${row.tld}`}>{row.name}</a> 
                    ) : (
                    <p onClick={() => alert(MSG_UNLOCK_LEADS)}><u>{row.name}</u></p>
                    )
                  ) : (
                    <p>{row.name}</p>
                  )}
                </Card.Text>
                <Card.Text>
                  <span id={`lead-phone-${row.id}`}>{(row.phones === '') ? '' : (<p className='m-0'>{row.phones?.split(';')[0]}</p>)}</span>
                  <span id={`lead-email-${row.id}`}>{(row.emails === '') ? '' : (<p className='m-0'>{row.emails?.split(';')[0]}</p>)}</span>
                </Card.Text>
                <Card.Text className='m-0 p-0 mt-auto d-flex flex-row flex-wrap'>
                  {(row.contacturl === '') ? '' : this.urlRenderer({value: row.contacturl, icon: 'bi bi-card-heading', isunlocked: row.isunlocked, title: 'Contact Page' })}
                  {(row.abouturl === '') ? '' : this.urlRenderer({value: row.abouturl, icon: 'bi bi-buildings', isunlocked: row.isunlocked, title: 'About Page' })}
                  {(row.leadershipurl === '') ? '' : this.urlRenderer({value: row.leadershipurl, icon: 'bi bi-people-fill', isunlocked: row.isunlocked, title: 'Team Page' })}
                  {(row.careersurl === '') ? '' : this.urlRenderer({value: row.careersurl, icon: 'bi bi-person-workspace', isunlocked: row.isunlocked, title: 'Careers Page' })}
                  {(row.linkedinurl === '') ? '' : this.urlRenderer({value: row.linkedinurl, icon: 'bi bi-linkedin', isunlocked: row.isunlocked, title: 'LinkedIn' })}
                  {(row.facebookurl === '') ? '' : this.urlRenderer({value: row.facebookurl, icon: 'bi bi-facebook', isunlocked: row.isunlocked, title: 'Facebook' })}
                  {(row.twitterurl === '') ? '' : this.urlRenderer({value: row.twitterurl, icon: 'bi bi-twitter-x', isunlocked: row.isunlocked, title: 'Twitter' })}
                  {(row.instagramurl === '') ? '' : this.urlRenderer({value: row.instagramurl, icon: 'bi bi-instagram', isunlocked: row.isunlocked, title: 'Instagram' })}
                  {(row.youtubeurl === '') ? '' : this.urlRenderer({value: row.youtubeurl, icon: 'bi bi-youtube', isunlocked: row.isunlocked, title: 'YouTube' })}
                  {(row.pinteresturl === '') ? '' : this.urlRenderer({value: row.pinteresturl, icon: 'bi bi-pinterest', isunlocked: row.isunlocked, title: 'Pinterest' })}
                  {(row.whatsappurl === '') ? '' : this.urlRenderer({value: row.whatsappurl, icon: 'bi bi-whatsapp', isunlocked: row.isunlocked, title: 'WhatsApp' })}
                  <div className="ms-auto" style={{width: '18px', height:'18px'}}><i title={`Site Scan ${(row.iscrawlcomplete === 1) ? 'Completed' : 'Pending'}`} className={`mx-1 bi ${(row.iscrawlcomplete === 1) ? 'bi-check-circle-fill' : 'bi-circle-half'} text-dark`}></i></div>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
            ))}
        </div>
        ) : <div></div>}
      </SplitPane>
    </React.Fragment>
    )}
}

export default Leads;