import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Modal, Form, OverlayTrigger, Tooltip,Tab, Nav } from 'react-bootstrap'
function StartModal(props) {
    
    const [mode, setMode] = useState(2)
    const [excludeCaptcha, setExcludeCaptcha] = useState(0);
    const [excludeFailed, setExcludeFailed] = useState(0);

    const resetMode = () =>{
        setMode(2)// resetting mode back to the default manual review mode
    }

    const handleModeCheckbox = (event) => {
        if(event.target.checked) setMode(1)
        else setMode(2)
    }

	const handleCaptchaCheckbox = (event) => {
		if(event.target.checked) setExcludeCaptcha(1)
		else setExcludeCaptcha(0)
	}

	const handleFailedCheckbox = (event) => {
		if(event.target.checked) setExcludeFailed(1)
		else setExcludeFailed(0)
	}

    const handlejobselect = (event) => {
        props.setSelectedJob(event.target.value)
    }

    const startcampaign = () => {
		if (props.selectedJob > 0) {
            props.setJobsLoading(true)
			axios.post(`/startformcampaign`,{ 
				tid : props.selectedTemplate.id, 
				jid : props.selectedJob, 
				mid : mode, 
				startedby : props.uid,
				excludeCaptcha : excludeCaptcha,
				excludeFailed : excludeFailed
			 })
				.then(res => {
					props.setJobsLoading(false)
                    alert(`Campaign Started!`)
                    props.setShowstartmodal(false)
					// props.setCampaignRefresh(prevdata => !prevdata)
				})
				.catch(err => {
                    console.log(err)
                })
		} else {
            alert('You need to select a list to start a campaign')
		}

    }

    return(
        <>
        <Modal
				show={props.showstartmodal}
				onHide={() => {
					props.setShowstartmodal(false);
					resetMode();
				}}
				centered
				size="lg">
				<Modal.Header closeButton={true}>
					<div className="fs-6">Start Campaign</div>
				</Modal.Header>
				<Modal.Body className="m-1 p-3">
					<Form>
						<Form.Group className="mb-3">
							<Form.Label>Lists</Form.Label>
							<Form.Select id="selectOptions" onChange={handlejobselect}>
								<option key="default" value={-1}>
									Select list to start a campaign
								</option>
								{props.jobs.map((option, index) => (
									<option key={index} value={option.id} disabled={[2, 3].includes(option.status) ? false : true}>
										{option.name} [ID-{props.uid}00{option.id}] ( {option.formcount} forms found )
									</option>
								))}
							</Form.Select>
						</Form.Group>

						<div className="p-2 d-flex justify-content-between">
							<div>
								<div class="form-check form-switch">
									<input type="checkbox" id="modecheck" onChange={handleModeCheckbox} class="form-check-input" />

									<label title="" for="modecheck" class="form-check-label">
										Fast-Forward Mode
									</label>

									<OverlayTrigger key="tooltip-mode" placement={"right"} overlay={<Tooltip id={`tooltip-mode}`}>In fast-Forward mode, all automatable forms will be submitted. In default Manual Review mode, You can review which Forms you actually want to submit.</Tooltip>}>
										<i class="bi bi-question-circle-fill ps-1"></i>
									</OverlayTrigger>
								</div>
							</div>

							<div>
								<div class="form-check form-switch">
									<input type="checkbox" id="modecheck" onChange={handleCaptchaCheckbox} class="form-check-input" />

									<label title="" for="modecheck" class="form-check-label">
										Exclude Captcha
									</label>

									<OverlayTrigger key="tooltip-mode" placement={"right"} overlay={<Tooltip id={`tooltip-mode}`}>Check This to Exclude all Forms Where Captcha was detected.</Tooltip>}>
										<i class="bi bi-question-circle-fill ps-1"></i>
									</OverlayTrigger>
								</div>
							</div>

							<div>
								<div class="form-check form-switch">
									<input type="checkbox" id="modecheck" onChange={handleFailedCheckbox} class="form-check-input" />

									<label title="" for="modecheck" class="form-check-label">
										Exclude Previously Failed
									</label>

									<OverlayTrigger key="tooltip-mode" placement={"right"} overlay={<Tooltip id={`tooltip-mode}`}>Check This to Exclude all Forms That have a history of Failing.</Tooltip>}>
										<i class="bi bi-question-circle-fill ps-1"></i>
									</OverlayTrigger>
								</div>
							</div>

							<div>
								<Button className="mx-1" title="Cancel" variant="secondary" onClick={() => props.setShowstartmodal(false)}>
									Cancel
								</Button>

								
									<Button className={`mx-1`} title="Start a campaign with selected Template" variant="primary" onClick={startcampaign}>
									{props.jobsLoading ? (
										<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
								) : (<>Start Campaign</>)}
									</Button>
							
							</div>
						</div>
					</Form>
				</Modal.Body>
				<Modal.Footer style={{justifyContent: "flex-start"}}>
					<p className="mx-1">Note : You cannot run campaign for a paused list. Please wait for the List to get completed or Stop the crawl Job to run a campaign on that list</p>
				</Modal.Footer>
			</Modal>
        </>
    )
}   

export default StartModal