import React, {useState, useEffect, useCallback, useRef, useMemo} from 'react';
import axios from 'axios';
import {Tab, Nav, Button, Modal, Form} from 'react-bootstrap';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import 'ag-grid-community/styles/ag-theme-balham.css'
import 'ag-grid-enterprise'

import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';

const animatedComponents = makeAnimated();

const CUSTOM_DATA_TAB = 'custom_data';

const GRID_THEME_ALPINE = 'alpine';
const GRID_THEME_BALHAM = 'balham';

const CATEGORYGROUPS_ALLOWED_CUSTOMDATA_IDS=[16,17,22]

function CustomData(props) {
    const gridRef = useRef();
    const agRequestRef = useRef();

    const [isTableChanged, setIsTableChanged] = useState(false);
    const [selectedTable, setSelectedTable] = useState([]);
    const [tables, setTables] = useState([]);
    const [inputTable, setInputTable] = useState('');
    const [updatingRowData, setUpdatingRowData] = useState(false);
    const [rowTotalCount, setRowTotalCount] = useState(0);
    const [shouldCountSql, setShouldCountSql] = useState(false);
    const [resetTable, setResetTable] = useState(false);
    const [showSaveModal, setShowSaveModal] = useState(false);
    const [listName, setListName] = useState('');
    const [gridTheme, setGridTheme] = useState(GRID_THEME_BALHAM);
    const [exportingData, setExportingData] = useState(false);
    const [categoryGroups,setCategoryGroups]=useState([]);
    const [selectedCategoryGroup,setSelectedCategoryGroup]=useState(-1);

    const refreshCustomData = () => {
        axios.get(`/customdata`)
        .then(res => {
            setTables(res.data);
            setSelectedTable(res.data[0]);
        })
        .catch((error) => {
          console.log(error);
        })   
    }

    useEffect(() => {
        refreshCustomData();
    }, [props])

    useEffect(() => {
        setListName(selectedTable?.label ?? '')
        setIsTableChanged(true);
        forceRefresh(true);
        setSelectedCategoryGroup(-1)
    }, [selectedTable, shouldCountSql])

    useEffect(() => {
        setIsTableChanged(true);
        forceRefresh(false);
    }, [resetTable])

    useEffect(()=>{
        axios.get(`/usercategorygroups/${props.loggedInUserId}`)
        .then(res => {
            setCategoryGroups(res.data);
            // setSelectedTable(res.data[0]);
        })
        .catch((error) => {
          console.log(error);
        })
    },[])

    useEffect(() => {
        // setListName(selectedTable?.label ?? '')
        // setIsTableChanged(true);
        forceRefresh(true);
    }, [selectedCategoryGroup])

    const onTableChange = (o) => {
        setSelectedTable(o);
    }

    const onCategoryGroupChange = (o) => {
        setSelectedCategoryGroup(o);
    }

    const onTableInputChange = (value, e) => {
        if (e.action === 'input-change') {
            this.setState({inputTable: value});
        }
    }

    const onTableMenuClose = () => {
        setInputTable('')
    }

    const autoGroupColumnDef = {
        headerName: 'Group',
        width: 500,
        cellRenderer: 'agGroupCellRenderer',
    };
    
    const getChildCount = useCallback((data) => {
        return data ? data.childCount : undefined
    }, [])
    
    const popupParent = useMemo(() => {
        return document.body;
    }, [])

    const sideBar = useMemo(() => {
        return {
          toolPanels: [
            {
              id: 'columns',
              labelDefault: 'Columns',
              labelKey: 'columns',
              iconKey: 'columns',
              toolPanel: 'agColumnsToolPanel',
            },
            {
              id: 'filters',
              labelDefault: 'Filters',
              labelKey: 'filters',
              iconKey: 'filter',
              toolPanel: 'agFiltersToolPanel',
            }
          ],
          defaultToolPanel: '',
        };
    }, []);
    
    const gridOptions = {
        rowModelType: 'serverSide',
    
        defaultColDef: {
          editable: true,
          sortable: true,
          filter: true,
          resizable: true,
          floatingFilter: true,
          enableValue: true,
          enableRowGroup: true,
          enablePivot: true,
          filterParams: {
            buttons: ["reset"],
            closeOnApply: true,
          },
        },
    
        cacheBlockSize: 50,
        rowGroupPanelShow: 'always',
        rowSelection: 'multiple',
        columnHoverHighlight: true,
        suppressRowGroupHidesColumns: true,
        autoGroupColumnDef: autoGroupColumnDef,
        getChildCount: getChildCount,
        popupParent: popupParent,
        sideBar: sideBar,
    }
    
    const serverApiUrl = '/api/agcustomdata';

    const datasource = {
        getRows: function (params) {
          const { request, tablename, db, iscountsql, selectedCategoryGroup } = params;
          const settablename = tablename ?? '';
          const setdb = db ?? '';
          const countsql = iscountsql ?? shouldCountSql;
          agRequestRef.current = request;
          const selectedCategoryGroupId=selectedCategoryGroup?selectedCategoryGroup.value:0

          setUpdatingRowData(true);
          fetch(serverApiUrl, {
            method: 'post',
            body: JSON.stringify({ ...request, settablename, setdb, countsql,selectedCategoryGroupId }),
            headers: { "Content-Type": "application/json; charset=utf-8" }
          })
            .then(httpResponse => httpResponse.json())
            .then(response => {
              params.successCallback(response.rows, response.lastRow);
              setRowTotalCount(response.totals.Totals ?? 0);
              setUpdatingRowData(false);;;
            })
            .catch(error => {
              console.error(error);
              params.failCallback();
              setUpdatingRowData(false);
            });
        }
    };
    
    const onModelUpdated = (params) => {
        if (!isTableChanged) return;

        const detectDataType = (key, sampleRow) => {
            const value = sampleRow[key];
        
            if (typeof value === "number") return "agNumberColumnFilter";
            if (typeof value === "boolean") return "agSetColumnFilter"; // Boolean values
            // if (Date.parse(value) && !isNaN(Date.parse(value))) return "agDateColumnFilter"; // Valid date
            return "agTextColumnFilter"; // Default to text
        };
        
        if (params.api.getDisplayedRowCount() > 0) {
            const rowData = params.api.getDisplayedRowAtIndex(0);

            if (rowData && rowData.data) {
                const cols = []; 

                Object.keys(rowData.data).forEach(key => {
                    const isindexed=selectedTable.sortgroupcols?selectedTable.sortgroupcols.split(',').includes(key.trim()):false
                    cols.push({
                        field: key, 
                        filter: detectDataType(key, rowData.data),
                        sortable:isindexed,
                        enableRowGroup:isindexed
                    })
                })

                params.api.setColumnDefs(cols);

                setIsTableChanged(false);
            }
        }
    }

    const onGridReady = (params) => {
        params.api.setServerSideDatasource(datasource);
    };

    const forceRefresh = (restoreGridState) => {
        const asyncRefresh = async (tablename, db, iscountsql) => {
            gridRef.current?.api?.setColumnDefs([]);
            gridRef.current?.api?.setFilterModel([]);
            const updatedDataSource = { ...datasource, getRows: (params) => datasource.getRows({ ...params, tablename, db, iscountsql, selectedCategoryGroup }) };
            gridRef.current?.api?.setServerSideDatasource(updatedDataSource);    
            if (restoreGridState) {
                loadGridState();
            }
        }
        const tableName = selectedTable?.tablename;
        const dbName = selectedTable?.dbname; 

        asyncRefresh(tableName, dbName, shouldCountSql);
    }
      
    const tabRefresh = () => {
        gridRef.current.api.refreshServerSide({purge: true});
    }

    const themeRefresh = () => {
        setGridTheme((gridTheme === GRID_THEME_ALPINE) ? GRID_THEME_BALHAM : GRID_THEME_ALPINE);
        gridRef.current.api.refreshServerSide({purge: true});
    }

    const toggleCountSql = () => {
        setShouldCountSql(!shouldCountSql)
    }

    const forceReset = () => {
        setResetTable(!resetTable)
    }

    const saveGridState = async (isNew) => {
        if (!selectedTable || !(gridRef.current && gridRef.current.api && gridRef.current.columnApi)) return;

        const listId = selectedTable.value;
        const tableName = selectedTable.tablename;
        const dbName = selectedTable.dbname; 

        try {
            const gridState = {
                columnState: gridRef.current.columnApi.getColumnState(),
                columnDefs: gridRef.current.api.getColumnDefs(),
                filterState: gridRef.current.api.getFilterModel(),
            };
            // localStorage.setItem(`wow-${dbName}-${tableName}`, JSON.stringify(gridState));

            if (isNew) {
                axios.post(`/newcustomdata`, {name:listName, dbname: dbName, tablename: tableName, state:JSON.stringify(gridState)})
                .then(res => {
                    alert("Configuration saved");
                })
                .catch((error) => {
                    throw(error)
                })  
            } else {
                axios.post(`/updatecustomdata`, {id:listId, name:listName, state:JSON.stringify(gridState)})
                .then(res => {
                    alert("Configuration saved");
                })
                .catch((error) => {
                    throw(error)
                })  
            }
    
        } catch (err) {
            console.log(err)
            alert('Error while saving configuration')
        }  
    };

    const loadGridState = async () => {
        if (!selectedTable || !(gridRef.current && gridRef.current.api && gridRef.current.columnApi)) return;

        const listId = selectedTable.value;
        // const tableName = selectedTable.tablename;
        // const dbName = selectedTable.dbname; 
        // const savedState = localStorage.getItem(`wow-${dbName}-${tableName}`);

        axios.get(`/customdata/${listId}`)
        .then(res => {
            if (res && res.data && res.data[0].gridstate) {
                const gridState = JSON.parse(res.data[0].gridstate); console.log(gridState)
                gridRef.current.api.setColumnDefs(gridState.columnDefs);
                gridRef.current.api.setFilterModel(gridState.filterState);
                gridRef.current.columnApi.applyColumnState({state: gridState.columnState, applyOrder: true});
                setIsTableChanged(false);
            }
        })
        .catch((error) => {
            throw(error)
        })  

    };

    const changeListName = (e) => {
        setListName(e.target.value)
    }

    const startSave = () => {
        setShowSaveModal(true)
    }

    const cancelSave = () => {
        setShowSaveModal(false)
    }

    const saveNewList = () => {
        setShowSaveModal(false);
        saveGridState(true);
        refreshCustomData();
    }

    const saveList = () => {
        setShowSaveModal(false);
        saveGridState(false);
    }

    const exportData = async (allColumns) => {
          setExportingData(true);
          const serverURL = `/api/exportcustomdata/`;
          const settablename = selectedTable.tablename;
          const setdb = selectedTable.dbname; 
          const countsql = false;
          const columnHeaders = gridRef.current.api.getColumnDefs().map(e => (allColumns ? ({"key": e.field, "header": e.field}) : (e.hide ? '' : ({"key": e.field, "header": e.field}))));
      
          fetch(serverURL, {
            method: 'post',
            body: JSON.stringify({ ...agRequestRef.current, settablename, setdb, countsql, columnHeaders }),
            headers: { "Content-Type": "application/json; charset=utf-8" }
          })
          .then(response => {
            response.blob().then(blob => {
              let url = window.URL.createObjectURL(blob);
              let a = document.createElement('a');
              a.href = url;
              a.download = `export-${Math.round(Math.random()*1000)}.xlsx`;
              a.click();
              a.remove();
      
              setExportingData(false);
            })
          })
          .catch(error => {
            console.error(error);
          })
    }

    const exportAllColumns = () => {
        exportData(true)
    }

    const exportSelectedColumns = () => {
        exportData(false)
    }
       
    return (
        <div className='container-fluid m-0 p-0 d-flex flex-column' style={{ height: 'calc(100%)' }}>
            <Tab.Container defaultActiveKey={CUSTOM_DATA_TAB} >
                <Nav variant='tabs' className='fs-6 border' >
                    <Nav.Item>
                        <Nav.Link eventKey={CUSTOM_DATA_TAB} id={CUSTOM_DATA_TAB}>Custom Data</Nav.Link>
                    </Nav.Item>
                    <Nav.Item className='ms-auto d-flex'>
                    <Select
                        id='select_categorygroups'
                        className={`sm border border-primary rounded ms-2 ${CATEGORYGROUPS_ALLOWED_CUSTOMDATA_IDS.includes(selectedTable.value) ?'d-flex':'d-none'}`}
                        isMulti={false}
                        isClearable={true}
                        backspaceRemovesValue={true}
                        closeMenuOnSelect={true}
                        value={selectedCategoryGroup}
                        onChange={onCategoryGroupChange}
                        options={categoryGroups}
                        components={animatedComponents}
                        // onInputChange={onTableInputChange}
                        // onMenuClose={onTableMenuClose}
                        // inputValue={inputTable}
                        isDisabled={false}
                        isOptionDisabled={() => false}
                        menuPortalTarget={document.body}
                        styles={{ 
                            menuPortal: base => ({ ...base, zIndex: 9999 }), 
                            menu: (base) => ({...base,  width: "max-content", minWidth: "100%" }), 
                        }}
                    />
                    <Select
                        id='select_table'
                        className="sm border border-primary rounded ms-2"
                        isMulti={false}
                        isClearable={true}
                        backspaceRemovesValue={true}
                        closeMenuOnSelect={true}
                        value={selectedTable}
                        onChange={onTableChange}
                        options={tables}
                        components={animatedComponents}
                        onInputChange={onTableInputChange}
                        onMenuClose={onTableMenuClose}
                        inputValue={inputTable}
                        isDisabled={false}
                        isOptionDisabled={() => false}
                        menuPortalTarget={document.body}
                        styles={{ 
                            menuPortal: base => ({ ...base, zIndex: 9999 }), 
                            menu: (base) => ({...base,  width: "max-content", minWidth: "100%" }), 
                        }}
                    />
                    <Button className={`mx-1 float-right ${shouldCountSql ? 'd-block' : 'd-none'}`} variant="outline-primary">
                        {updatingRowData ? (<React.Fragment>&nbsp;<i className="fa fa-spinner fa-pulse"></i></React.Fragment>) : `Count: ${Number(rowTotalCount).toLocaleString()}`}
                    </Button>
                    <Button title="Count / Do not Count" className={`btn btn-primary mx-1 float-right`} onClick={toggleCountSql}>
                        <i className={`bi ${shouldCountSql ? 'bi-circle' : 'bi-plus-circle'}`}></i>
                    </Button>
                    <Button title="Save Configuration" className={`btn btn-primary mx-1 float-right`} disabled ={!selectedTable} onClick={startSave}>
                        <i className={`bi bi-floppy`}></i>
                    </Button>
                    <Button title="Reset Configuration" className={`btn btn-primary mx-1 float-right`} disabled ={!selectedTable} onClick={forceReset}>
                        <i className={`bi bi-eraser`}></i>
                    </Button>
                    <Button title="Excel Export" className={`btn btn-primary mx-1 float-right`} disabled={!selectedTable || exportingData || selectedTable.dbname=='clickhouse'} onClick={exportAllColumns} onContextMenu={exportSelectedColumns} >
                        <i className={`${exportingData ? 'fa fa-spinner fa-pulse' : 'bi bi-download'}`}></i>
                    </Button>
                    <Button title="Refresh Data" className={`btn btn-primary float-right me-1`} disabled ={!selectedTable} onClick={tabRefresh} onContextMenu={themeRefresh}>
                        <i className={`bi bi-arrow-repeat`}></i>
                    </Button>
                    </Nav.Item>
                </Nav>
                <Tab.Content className='w-100 h-100' animation={true} mountOnEnter={true} unmountOnExit={true} >
                    <Tab.Pane className='w-100 h-100' eventKey={CUSTOM_DATA_TAB}>
                        <div className={`w-100 h-100 ${selectedTable ? 'd-none' : 'd-flex'} fs-6 justify-content-center`}>
                            Please select a List
                        </div>
                        <div className={`ag-theme-${gridTheme} w-100 h-100 ${selectedTable ? 'd-block' : 'd-none'}`}>
                            <AgGridReact
                                ref={gridRef}
                                gridOptions={gridOptions}
                                onGridReady={onGridReady}
                                onModelUpdated={onModelUpdated}
                            />
                        </div>
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>
            <Modal show={showSaveModal} onHide={cancelSave} centered>
              <Modal.Body className='p-2 shadow'>
                <div className='d-flex flex-column'>
                  <Form.Group className="m-3">
                    <Form.Label className='fs-6 fw-bold'>List Name</Form.Label>
                    <Form.Control
                      type='text'
                      defaultValue={listName}
                      placeholder='List Name'
                      autoFocus
                      onChange={changeListName}
                    />
                  </Form.Group>
                </div>
                <div className='p-2 d-flex justify-content-end'>
                  <Button className='mx-1' variant="secondary" onClick={cancelSave}>
                    Cancel
                  </Button>
                  <Button className={`mx-1 ${((listName === '') || (listName.trim().toLowerCase() === (selectedTable?.label ?? '').trim().toLowerCase())) ? 'disabled' : ''}`} variant="primary" onClick={saveNewList}>
                    Save New
                  </Button>
                  <Button className={`mx-1 ${(listName === '') ? 'disabled' : ''}`} variant="primary" onClick={saveList}>
                    Save
                  </Button>
                </div>
              </Modal.Body>
            </Modal>
        </div>
    )
}

export default CustomData;