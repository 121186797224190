import React, {Component} from "react";
import {AgGridReact} from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "ag-grid-enterprise";
import {Modal, Image, Button, Tab, Tabs, Nav} from "react-bootstrap";
import {TransformWrapper, TransformComponent, useControls} from "react-zoom-pan-pinch";

const submitcolumn = {
	field: "submitstatus",
	colId: "submit",
	headerName: "Submit ",
	valueFormatter: params => (params.data.submitstatus === "NA" && params.data.iscaptcha === "No" ? "" : "NA"),
	checkboxSelection: params => params.data.submitstatus === "NA" && params.data.iscaptcha === "No" && params.data.fillstatus === "Success",
	headerCheckboxSelection: true,
	headerCheckboxSelectionFilteredOnly: true,
};
const defaultColDef = {
	filter: true,
	sortable: true,
	resizable: true,
	enableRowGroup: true,
};
const autoGroupColumnDef = {
	pinned: "left",
};
const defaultSideBar = {
	toolPanels: [
		{
			id: "filters",
			labelDefault: "Filters",
			labelKey: "filters",
			iconKey: "filter",
			toolPanel: "agFiltersToolPanel",
			toolPanelParams: {
				suppressExpandAll: true,
				suppressFilterSearch: true,
			},
		},
		{
			id: "columns",
			labelDefault: "Columns",
			labelKey: "columns",
			iconKey: "column",
			toolPanel: "agColumnsToolPanel",
			toolPanelParams: {
				suppressExpandAll: true,
				suppressFilterSearch: true,
			},
		},
	],
};

const rowSelection = "single";
const rowGroupPanelShow = "onlyWhenGrouping";

const rowModelType = "serverSide";
const cacheBlockSize = 50;

class ImageCellRenderer extends Component {
	render() {
		const imageBase64 = this.props.value;
		return imageBase64 ? (
			<React.Fragment style={{height: "80vh", width: "100%"}}>
				<img src={`data:image/png;base64,${imageBase64}`} alt="Image" style={{width: "50px", height: "50px"}} />
			</React.Fragment>
		) : null;
	}
}

class CampaignDetails extends Component {
	constructor(props) {
		super(props);

		this.state = {
			submitlist: [],
			showscreenshot: false,
			screenshotid: 0,
			gridApi: null,
			rowData: [],
			key: "formscreenshot",
			gridkey: "autosubmit",
			columnDefs: [
				submitcolumn,
				{
					field: "Sr. No.",
					header: "Sr. No.",
					valueGetter: "node.rowIndex + 1",
					width: 80,
				},
				{
					field: "id",
					headerName: "ID",
					filter: "agTextColumnFilter",
					width: 100,
					headerClass: "custom-header-class",
					hide: true,
				},
				{
					field: "jobname",
					header: "List",
					filter: "agTextColumnFilter",
					width: 300,
					headerClass: "custom-header-class",
					hide: true,
				},
				{
					field: "domain",
					header: "Domain",
					filter: "agTextColumnFilter",
					width: 200,
					headerClass: "custom-header-class",
				},
				{
					field: "fillstatus",
					header: "Fill Status",
					filter: "agTextColumnFilter",
					width: 130,
					headerClass: "custom-header-class",
				},
				{
					field: "submitstatus",
					header: "Submit Status",
					filter: "agTextColumnFilter",
					width: 130,
					headerClass: "custom-header-class",
				},
				// {
				//   field: "formscreenshot",
				//   header: "Screenshot1",
				//   filter: "agTextColumnFilter",
				//   width: 130,
				//   // onCellClicked: this.onS1CellClicked.bind(this),
				// },
				// {
				//   field: "submitscreenshot",
				//   header: "Screenshot2",
				//   filter: "agTextColumnFilter",
				//   width: 130,
				//   // onCellClicked: this.onS2CellClicked.bind(this),
				// },
				{
					field: "iscaptcha",
					header: "captcha present",
					filter: "agTextColumnFilter",
					width: 100,
					headerClass: "custom-header-class",
				},
			],
			selectedData: [],
			updatingRowData: false,
			frameworkComponents: {
				imageCellRenderer: ImageCellRenderer,
			},
		};

		this.onGridReady = this.onGridReady.bind(this);
		this.getChildCount = this.getChildCount.bind(this);
		this.onSelectionChanged = this.onSelectionChanged.bind(this);
		this.onModelUpdated = this.onModelUpdated.bind(this);
		this.onRowDataUpdated = this.onRowDataUpdated.bind(this);
		this.onRefresh = this.onRefresh.bind(this);
		this.setshowscreenshot = this.setshowscreenshot.bind(this);
		this.getimage = this.getimage.bind(this);
		this.onRowClick = this.onRowClick.bind(this);
		this.setKey = this.setKey.bind(this);
		this.setGridKey = this.setGridKey.bind(this);
		this.submitforms = this.submitforms.bind(this)
		this.deselectAll = this.deselectAll.bind(this.deselectAll)
		this.submitlistmanager = this.submitlistmanager.bind(this.submitlistmanager);
	}

	fetchData = async jid => {
		if (!this.state.updatingRowData) {
			this.setState({updatingRowData: true});

			if (jid > 0) {
				this.state.gridApi?.setServerSideDatasource({getRows: this.getRows});
				var rowCount = this.state.gridApi?.getDisplayedRowCount();
			}
			// this.setState({ updatingRowData: false });
		}
	};

	setKey = value => {
		this.setState({key: value});
	};

	setGridKey = value => {
		this.setState({gridkey: value});
		this.state.gridApi.setServerSideDatasource({getRows: this.getRows});
		if (value === "autosubmit" && this.state.columnDefs[0].field !== "submitstatus")
			this.setState(prevState => ({
				columnDefs: [submitcolumn, ...prevState.columnDefs],
			}));

		if ((value === "manualreview" || value === "all") && this.state.columnDefs[0].field === "submitstatus")
			this.setState(prevState => ({
				columnDefs: prevState.columnDefs.slice(1),
			}));
	};

	groupCountComparator(valueA, valueB, nodeA, nodeB, isInverted) {
		// Get the group counts for the two groups
		const countA = nodeA ? nodeA.allChildrenCount : valueA; // Number of children in group A
		const countB = nodeB ? nodeB.allChildrenCount : valueB; // Number of children in group B

		// Compare the counts and return the result
		if (countA < countB) {
			return -1;
		} else if (countA > countB) {
			return 1;
		} else {
			return 0;
		}
	}

	onGridReady = params => {
		this.setState({
			gridApi: params.api,
		});
		params.api.setServerSideDatasource({getRows: this.getRows});
	};

	onRefresh = params => {
		this.state.gridApi.setServerSideDatasource({getRows: this.getRows});
	};

	getimage = id => {
		fetch(`/api/getscreenshot/${id}/formscreenshot`, {method: "get"})
			.then(httpResponse => httpResponse.json())
			.then(response => {
				document.getElementById("formscreenshot").src = "data:image/png;base64, " + (response[0]?.image || "");
			})
			.then(() => fetch(`/api/getscreenshot/${id}/submitscreenshot`, {method: "get"}))
			.then(httpResponse => httpResponse.json())
			.then(response => {
				document.getElementById("submitscreenshot").src = "data:image/png;base64, " + (response[0]?.image || "");
			})
			.catch(error => console.error(error));
	};

	deselectAll = () => {
		this.state.gridApi.deselectAll()
	}

	submitforms = () => {
		
		// this.onRefresh()
		this.deselectAll()
		fetch("/api/submitforms", {
			method: "POST",
			body: JSON.stringify({ ids: this.state.submitlist, cid: this.props.crid }),
			headers: {
				"Content-Type": "application/json",
			  },
		})
		.then(httpResponse => httpResponse.json())
		.then(response => {
			alert("Selected Forms have been queued for Submission!!!");
			this.setState({submitlist:[]})
		})
		.catch(error => {
			alert('Error! Unable to Queue selected forms. Please try again.')
			console.error(error)
		});
	}


	getRows = params => {
		const serverURL = `/api/getagcdetails/${this.props.crid}/${this.state.gridkey}`;

		this.setState({updatingRowData: true});
		fetch(serverURL, {
			method: "post",
			body: JSON.stringify(params.request),
			headers: {"Content-Type": "application/json; charset=utf-8"},
		})
			.then(httpResponse => httpResponse.json())
			.then(response => {
				console.log(response);
				params.successCallback(response.rows, response.lastRow);
				this.setState({updatingRowData: false});
			})
			.catch(error => {
				console.error(error);
				params.failCallback();
			});
	};

	getChildCount(data) {
		return data ? data.childCount : undefined;
	}

	onSelectionChanged() {
		if (this.state.isGridView) {
			const data = this.state.gridApi.getSelectedRows();
			this.setState({
				selectedData: data,
			});
			this.props.data(data);
		}
	}

	submitlistmanager = params => {
		const selectedRows = params.api.getSelectedRows();
		const selectedIds = selectedRows.map(row => row.id);
		this.setState({submitlist: selectedIds});
	};

	onModelUpdated = params => {
		this.setState({updatingRowData: false});
	};

	onRowDataUpdated = params => {};

	// onS1CellClicked(params) {
	//     // this.setshowscreenshot(true);
	//     this.getimage(params.data.id);
	// }

	// onS2CellClicked(params) {
	// if (params.colDef.field === "submitscreenshot") {
	//   this.setshowscreenshot(true);
	//   this.getimage(params.data.id, "submitscreenshot");
	// }
	// }
	onRowClick(params) {
		this.getimage(params.data.id);
	}

	setshowscreenshot(value) {
		this.setState({showscreenshot: value});
	}

	render() {
		const Controls = () => {
			const {zoomIn, zoomOut, resetTransform} = useControls();
			return (
				<>
					<Button size="sm" variant="light" onClick={() => zoomIn()}>
						<i class="bi bi-zoom-in"></i>
					</Button>{" "}
					<Button size="sm" variant="light" onClick={() => zoomOut()}>
						<i class="bi bi-zoom-out"></i>
					</Button>{" "}
					<Button size="sm" variant="light" onClick={() => resetTransform()}>
						<i class="bi bi-arrow-clockwise"></i>
					</Button>
				</>
			);
		};

		return (
			<div className="row h-100">
				<div className="col-6 overflow-hidden">
					<Tab.Container
						defaultActiveKey={this.state.gridkey}
						onSelect={k => {
							this.setGridKey(k);
							this.onRefresh(k);
						}}>
						<div className="row">
							<div className="col-10">
								<Nav variant="tabs" className="fs-6 border">
									<Nav.Item>
										<Nav.Link eventKey="autosubmit" id="autosubmit">
											Auto Submit
										</Nav.Link>
									</Nav.Item>
									<Nav.Item>
										<Nav.Link eventKey="manualreview" id="manualreview">
											Manual Review
										</Nav.Link>
									</Nav.Item>
									<Nav.Item>
										<Nav.Link eventKey="all" id="all">
											All
										</Nav.Link>
									</Nav.Item>
								</Nav>
							</div>
							<div className="col-2 align-self-center">
								<Button className={`btn btn-sm m-0 ${this.state.gridkey == "autosubmit" ? "" : "d-none"}`} disabled={false} onClick={this.submitforms}>
									Submit
								</Button>
							</div>
						</div>
						<Tab.Content className="w-100" style={{height: "calc(100% - 34px)"}} animation={true} mountOnEnter={true} unmountOnExit={true}>
							<div className="ag-theme-balham h-100 " style={{width: "100%", height: "84vh"}}>
								<AgGridReact
									onRowClicked={this.onRowClick}
									columnDefs={this.state.columnDefs}
									defaultColDef={defaultColDef}
									rowGroupPanelShow="always"
									rowMultiSelectWithClick={false}
									rowSelection="multiple"
									onGridReady={this.onGridReady}
									onGridRefresh={this.onRefresh}
									onSelectionChanged={this.submitlistmanager}
									onRowDataUpdated={this.onRowDataUpdated}
									onModelUpdated={this.onModelUpdated}
									rowModelType={rowModelType}
									getChildCount={this.getChildCount}
									cacheBlockSize={cacheBlockSize}
								/>
							</div>
						</Tab.Content>
					</Tab.Container>
				</div>

				<div className="col-6">
					<Tabs activeKey={this.key} onSelect={k => this.setKey(k)}>
						<Tab eventKey="formscreenshot" title="Review" className="overflow-scroll" style={{height: "75vh"}}>
							<TransformWrapper>
								<div
									style={{
										position: "absolute",
										right: "5%",
										zIndex: 2,
									}}>
									<Controls />
								</div>
								<TransformComponent>
								<Image id="formscreenshot" src="" fluid />
									{/* <div id="formscreenshot" style={{height: "72vh", width: "42vw"}}>
										
									</div> */}
								</TransformComponent>
							</TransformWrapper>
						</Tab>
						<Tab eventKey="submitscreenshot" title="Submit" className="overflow-scroll" style={{height: "75vh"}}>
							<TransformWrapper>
								<div
									style={{
										position: "absolute",
										right: "5%",
										zIndex: 2,
									}}>
									<Controls />
								</div>
								<TransformComponent>
									<Image id="submitscreenshot" src="" fluid />
								</TransformComponent>
							</TransformWrapper>
						</Tab>
					</Tabs>
				</div>

				{/* <Modal show={this.state.showscreenshot} onHide={() => this.setState({showscreenshot: false})} centered size="lg" style={{height: "95vh"}}>
					<TransformWrapper>
						<Modal.Header closeButton={true}>
							<div className="fs-6">Screenshot</div>
							<div style={{position: "absolute", right: "5%"}}>
								<Controls />
							</div>
						</Modal.Header>
						<Modal.Body className="m-1 p-0">
							<TransformComponent>
								<Image id="formscreenshot" src="" fluid />
							</TransformComponent>
						</Modal.Body>
					</TransformWrapper>
				</Modal> */}
			</div>
		);
	}
}

export default CampaignDetails;




